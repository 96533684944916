<template>
  <div>
    <span v-if="id">
      <router-link v-if="contract.id" :to="{ name: 'contract', params: { id: contract.id }}">
        {{ contract.name }}
      </router-link>
      VAT_ID: {{ contract.vat_id }}
      Effective Since: {{ contract.effective_since }} Until: {{ contract.effective_until }}
      Period: {{ contract.billing_period }}
    </span>
    <span v-else>---</span>
  </div>
</template>

<script>

export default {
  props: {
    id: {
      required: true,
    },
  },
  data() {
    return {
      contract: {},
      isFetching: false,
    };
  },
  mounted() {
    this.fetch();
  },
  methods: {
    async fetch() {
      if (!this.id) return;
      if (this.id === this.contract.id) return;
      this.isFetching = true;
      const { data } = await this.$http.get(`/v1/billing/contract/${this.id}`);
      this.isFetching = false;
      this.contract = data;
    },
  },
  watch: {
    id() {
      this.fetch();
    },
  },
};
</script>
