<template>
  <div class="table-container">
    <b-table
      :data="organizations"
      :loading="loading"
      striped
      hoverable
      bordered
      paginated
      backend-pagination
      backend-filtering
      backend-sorting
      pagination-size="is-small"
      :total="total"
      :per-page="perPage"
      @page-change="onPageChange"
      aria-next-label="Next page"
      aria-previous-label="Previous page"
      aria-page-label="Page"
      aria-current-label="Current page"
      :default-sort-direction="defaultSortOrder"
      :default-sort="[sortField, sortOrder]"
      @sort="onSort"
      @filters-change="filtersChange"
      :detailed="isAdmin"
      detail-key="id"
    >
      <b-table-column field="index" label="Index" v-slot="props">
        {{ sortOrder === 'asc' ? props.index + offset + 1 : total - props.index - offset }}
      </b-table-column>

      <b-table-column field="id" label="ID" sortable v-slot="props">
        <router-link :to="{ name: 'organization', params: { id: props.row.id } }">
          {{ props.row.id }}
        </router-link>
      </b-table-column>

      <b-table-column field="name" label="Name" sortable searchable>
        <template #searchable="props">
          <b-input v-model="props.filters[props.column.field]" placeholder="Search..." icon="magnify" size="is-small" />
        </template>
        <template v-slot="props">
          <router-link :to="{ name: 'groups', params: { organizationId: props.row.id } }">
            {{ props.row.name }}
          </router-link>
        </template>
      </b-table-column>

      <b-table-column field="enabled" label="Enabled" sortable v-slot="props">
        {{ props.row.enabled ? 'yes' : 'no' }}
      </b-table-column>

      <b-table-column v-if="isAdmin" field="contract_id" label="Contract ID" sortable v-slot="props">
        <router-link v-if="props.row.contract_id" :to="{ name: 'contract', params: { id: props.row.contract_id } }">
          {{ props.row.contract_id }}
        </router-link>
      </b-table-column>

      <b-table-column v-if="isAdmin" field="internal" label="Internal" sortable v-slot="props">
        {{ props.row.internal }}
      </b-table-column>

      <b-table-column field="actions" label="Actions" v-slot="props">
        <router-link :to="{ name: 'organization', params: { id: props.row.id } }">
          <b-button size="is-small" title="Detail">
            <b-icon icon="eye" size="is-small" />
          </b-button>
        </router-link>
      </b-table-column>

      <b-table-column field="link" label="" v-slot="props">
        <router-link :to="{ name: 'groups', params: { organizationId: props.row.id } }"> Groups </router-link>
      </b-table-column>

      <template slot="detail" slot-scope="props">
        <ContractDetail v-if="isAdmin" :id="props.row.contract_id"></ContractDetail>
      </template>

      <template slot="bottom-left">
        <b-button
          tag="router-link"
          v-if="isAdmin"
          :to="{ name: 'organization-add' }"
          type="is-success"
          icon-left="plus-box"
          >Add Organization</b-button
        >

        <b-button v-on:click="fetch" type="is-info" icon-left="refresh" outlined style="margin-left: 100px"
          >Refresh data</b-button
        >
      </template>
    </b-table>
  </div>
</template>

<script>
import { isEqual, pickBy, assign } from 'lodash';
import ContractDetail from '../components/ContractDetail.vue';

export default {
  name: 'organizations',
  components: { ContractDetail },
  data() {
    return {
      organizations: [],
      total: 0,
      page: 1,
      perPage: 20,
      sortField: 'name',
      sortOrder: 'asc',
      defaultSortOrder: 'asc',
      loading: false,
      offset: 0,
      params: {},
      columns: [
        {
          field: 'id',
          label: 'ID',
        },
        {
          field: 'name',
          label: 'Name',
          searchable: true,
        },
        {
          field: 'enable',
          label: 'Enabled',
        },
      ],
    };
  },
  created() {
    this.fetch();
  },
  computed: {
    isAdmin() {
      return this.$store.state.userIsAdmin;
    },
  },
  methods: {
    async fetch() {
      this.loading = true;
      const limit = this.perPage;
      const offset = (this.page - 1) * this.perPage;

      const params = assign(
        {
          sort_field: this.sortField,
          sort_order: this.sortOrder,
          limit,
          offset,
        },
        this.params
      );

      const res = await this.$http.get('/v1/organizations', { params });

      this.organizations = res.data;
      this.total = parseInt(res.headers['x-total'], 10);
      this.offset = offset;
      this.loading = false;
    },
    filtersChange(filters) {
      const params = pickBy(filters, (v) => v.length > 2);
      if (!isEqual(params, this.params)) {
        this.params = params;
        this.fetch();
      }
    },
    onPageChange(page) {
      this.page = page;
      this.fetch();
    },
    onSort(field, order) {
      this.sortField = field;
      this.sortOrder = order;
      this.fetch();
    },
  },
};
</script>
